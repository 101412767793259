import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import { className } from '../../utils';

import Arrow from '../../svgs/Arrow';
import ButtonCircle from '../../svgs/ButtonCircle';

import styles from './Button.module.scss';

const Button = ({
  url,
  text,
  external,
  circle,
  circleFull,
  circleTan,
  direction,
  margin,
  target,
}) => {
  const content = (
    <Fragment>
      {text}
      <div className={styles.arrow}>
        <Arrow />
      </div>
      {circle && !circleFull && !circleTan ? (
        <div className={styles.circle}>
          <ButtonCircle />
        </div>
      ) : null}
    </Fragment>
  );

  const cn = className(
    styles.button,
    direction === 'left' && styles.arrowLeft,
    margin && styles.buttonMargin,
    circleFull && styles.circleFull,
    circleTan && styles.circleTan,
  );

  return external ? (
    <a href={url} {...cn} target={target} rel={target === '_blank' ? 'noopener noreferrer' : ''}>
      {content}
    </a>
  ) : (
    <Link to={url} {...cn}>
      {content}
    </Link>
  );
};

Button.defaultProps = {
  external: false,
  circle: true,
  direction: 'right',
  margin: false,
};

Button.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  external: PropTypes.bool,
  circle: PropTypes.bool,
  circleFull: PropTypes.bool,
  circleTan: PropTypes.bool,
  direction: PropTypes.string,
  margin: PropTypes.bool,
  target: PropTypes.string,
};

export default Button;
