import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';
import Body from '../components/BasicPage/Body';
import Header from '../components/BasicPage/Header';
import Footer from '../components/Footer';
import ImageGrid from '../components/ImageGrid';
import Wrapper from '../components/Wrapper';

const Page = ({ data, location }) => {
  const page = data.prismic.allPages.edges.slice(0, 1).pop();

  if (!page) {
    return null;
  }

  const {
    title,
    content,
    hide_content_dropcap,
    photos,
    content_photos,
    content_two,
    content_photos_two,
    content_three,
    featured_image,
    featured_imageSharp,
    featured_video,
    featured_embed_video,
    section,
    cta,
    seo,
    topper_cta_link,
    topper_cta_label,
    secondary_topper_cta_link,
    secondary_topper_cta_label,
  } = page.node;
  const titleRaw = RichText.asText(title);
  const overline = section && section.section_title;

  const gridContentPhotos = content_photos ? content_photos.slice(0, 1).pop() : [];
  const gridContentPhotosTwo = content_photos_two ? content_photos_two.slice(0, 1).pop() : [];

  return (
    <>
      <SEO
        title={titleRaw}
        pageSeo={seo}
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />

      <Header
        title={title}
        photos={photos}
        image={featured_image}
        sharpImage={featured_imageSharp}
        video={featured_video}
        embedVideo={featured_embed_video}
        subnav={section ? section.navigation : null}
        overline={overline}
        ctaLink={topper_cta_link}
        ctaLabel={topper_cta_label}
        secondaryCtaLink={secondary_topper_cta_link}
        secondaryCtaLabel={secondary_topper_cta_label}
      />

      <Body content={content} dropcap={!hide_content_dropcap} />

      {gridContentPhotos && (gridContentPhotos.feature_photo || gridContentPhotos.feature_video) ? (
        <Wrapper>
          <ImageGrid photos={gridContentPhotos} flipOrientation />
        </Wrapper>
      ) : null}

      {content_two ? <Body content={content_two} dropcap={false} /> : null}

      {gridContentPhotosTwo &&
      (gridContentPhotosTwo.feature_photo || gridContentPhotosTwo.feature_video) ? (
          <Wrapper>
            <ImageGrid photos={gridContentPhotosTwo} flipOrientation />
          </Wrapper>
        ) : null}

      {content_three ? <Body content={content_three} dropcap={false} /> : null}

      <Footer cta={cta} />
    </>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allPages: PropTypes.object,
    }),
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
};

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }

            title
            topper_cta_link {
              ...LinkQuery
            }
            topper_cta_label
            secondary_topper_cta_link {
              ...LinkQuery
            }
            secondary_topper_cta_label
            content
            hide_content_dropcap
            content_two

            photos {
              feature_photo
              feature_photoSharp {
                ...FeaturePhotoQuery
              }
              feature_video {
                _linkType
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  name
                }
              }
              thumbnail_one
              thumbnail_oneSharp {
                ...ThumbnailQuery
              }
              thumbnail_two
              thumbnail_twoSharp {
                ...ThumbnailQuery
              }
            }

            content_photos {
              feature_photo
              feature_photoSharp {
                ...FeaturePhotoQuery
              }
              feature_video {
                _linkType
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  name
                }
              }
              thumbnail_one
              thumbnail_oneSharp {
                ...ThumbnailQuery
              }
              thumbnail_two
              thumbnail_twoSharp {
                ...ThumbnailQuery
              }
            }

            content_three

            content_photos_two {
              feature_photo
              feature_photoSharp {
                ...FeaturePhotoQuery
              }
              feature_video {
                _linkType
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  name
                }
              }
              thumbnail_one
              thumbnail_oneSharp {
                ...ThumbnailQuery
              }
              thumbnail_two
              thumbnail_twoSharp {
                ...ThumbnailQuery
              }
            }

            featured_image

            featured_imageSharp {
              ...FeaturePhotoQuery
            }
            featured_video {
              _linkType
              ... on PRISMIC__FileLink {
                _linkType
                url
                name
              }
            }

            featured_embed_video

            section {
              ... on PRISMIC_Site_section {
                section_title
                navigation {
                  label
                  link {
                    ...LinkQuery
                  }
                }
              }
            }

            cta {
              ...FooterCta
            }
          }
        }
      }
    }
  }
`;

export const linkFragmentQuery = graphql`
  fragment LinkQuery on PRISMIC__Linkable {
    _linkType

    ... on PRISMIC_Page {
      section {
        ... on PRISMIC_Site_section {
          section_slug
        }
      }
      _meta {
        uid
        type
      }
    }

    ... on PRISMIC_Job {
      _meta {
        uid
        type
      }
    }

    ... on PRISMIC_Press {
      _meta {
        uid
        type
      }
    }

    ... on PRISMIC_Tech_page {
      _meta {
        type
      }
    }

    ... on PRISMIC_Homepage {
      _meta {
        type
      }
    }

    ... on PRISMIC_Press_archive_page {
      _meta {
        type
      }
    }

    ... on PRISMIC_Careers_archive_page {
      _meta {
        type
      }
    }

    ... on PRISMIC__ExternalLink {
      url
    }

    ... on PRISMIC_Devens_updates_page {
      section {
        ... on PRISMIC_Site_section {
          section_slug
        }
      }
      _meta {
        uid
        type
      }
    }

    ... on PRISMIC_Devens_events_page {
      section {
        ... on PRISMIC_Site_section {
          section_slug
        }
      }
      _meta {
        uid
        type
      }
    }

    ... on PRISMIC_Devens_timeline_page {
      section {
        ... on PRISMIC_Site_section {
          section_slug
        }
      }
      _meta {
        uid
        type
      }
    }

    ... on PRISMIC_Devens_public_benefits_page {
      section {
        ... on PRISMIC_Site_section {
          section_slug
        }
      }
      _meta {
        uid
        type
      }
    }
  }
`;

export default Page;
