import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from '../../utils';

const Video = ({ className, video }) => {
  if (!video) {
    return null;
  }

  return (
    <video
      className={className}
      autoPlay
      loop
      muted
      playsInline
      controls={false}
      style={{ width: '100%' }}>
      <source src={video.url} type="video/mp4" />
    </video>
  );
};

Video.defaultProps = {
  video: null,
};

Video.propTypes = {
  className: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Video;
