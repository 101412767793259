/**
 * Layout component that queries for data.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getPathList } from '../utils';
import addPrismicScripts from '../utils/addPrismicScripts';

import { GlobalContextProvider } from '../context/globals';

import PageTransition from '../components/PageTransition';
import Nav from '../components/Nav/MainNav';

import '../styles/global.scss';

const Layout = ({ children, location }) => {
  useEffect(() => {
    addPrismicScripts();
  }, []);

  // List the pages that have the forest theme.
  const forestThemePages = getPathList(['/', '/technology']);
  const navTheme = forestThemePages.includes(location.pathname) ? 'forest' : 'tan';

  return (
    <div className="mainLayout">
      <a href="#site-main" className="skip-main">
        Skip to content
      </a>
      <GlobalContextProvider>
        <Nav newTheme={navTheme} />

        <PageTransition location={location}>
          <main
            id="site-main"
            className={`mainContent${navTheme === 'forest' ? ' mainContentOverlaid' : ''}`}>
            {children}
          </main>
        </PageTransition>
      </GlobalContextProvider>
    </div>
  );
};

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
};

export default Layout;
