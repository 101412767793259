import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { className } from '../../utils';

import Img from '../Image';
import Video from '../Video';

import styles from './ImageGrid.module.scss';

const ImageGrid = ({ photos, flipOrientation, overflowLastImage }) => {
  if (!photos || photos.length === 0) {
    return null;
  }

  const {
    feature_photo,
    feature_photoSharp,
    feature_video,
    thumbnail_one,
    thumbnail_oneSharp,
    thumbnail_two,
    thumbnail_twoSharp,
  } = photos;

  const thumbnails = (thumbnail_one || thumbnail_two) && (
    <div className={styles.thumbnails}>
      {thumbnail_one && (
        <div {...className(styles.image, styles.thumbnail)}>
          <Img image={thumbnail_one} sharpImage={thumbnail_oneSharp} />
        </div>
      )}

      {thumbnail_two && (
        <div
          {...className(
            styles.image,
            styles.thumbnail,
            overflowLastImage && styles.thumbnailOverflow,
          )}>
          <Img image={thumbnail_two} sharpImage={thumbnail_twoSharp} />
        </div>
      )}
    </div>
  );

  const featuredPhoto = feature_photo && (
    <div
      {...className(
        styles.image,
        styles.featured,
        thumbnail_two ? styles.featuredWithThumbnailTwo : undefined,
      )}>
      <Img image={feature_photo} sharpImage={feature_photoSharp} className={styles.imageInner} />
    </div>
  );

  const featuredVideo = feature_video && (
    <div
      {...className(
        styles.image,
        styles.featured,
        thumbnail_two ? styles.featuredWithThumbnailTwo : undefined,
      )}>
      <Video video={feature_video} className={styles.imageInner} />
    </div>
  );

  return (
    <figure className={styles.imageGrid}>
      {flipOrientation ? (
        <Fragment>
          {featuredVideo ? featuredVideo : featuredPhoto}
          {thumbnails}
        </Fragment>
      ) : (
        <Fragment>
          {thumbnails}
          {featuredVideo ? featuredVideo : featuredPhoto}
        </Fragment>
      )}
    </figure>
  );
};

ImageGrid.defaultProps = {
  photos: [],
  flipOrientation: false,
  overflowLastImage: false,
};

ImageGrid.propTypes = {
  photos: PropTypes.shape({
    length: PropTypes.number,
    feature_photo: PropTypes.object,
    feature_photoSharp: PropTypes.object,
    thumbnail_one: PropTypes.object,
    thumbnail_oneSharp: PropTypes.object,
    thumbnail_two: PropTypes.object,
    thumbnail_twoSharp: PropTypes.object,
  }),
  flipOrientation: PropTypes.bool,
  overflowLastImage: PropTypes.bool,
};

export default ImageGrid;
