import React from 'react';
import PropTypes from 'prop-types';

const ButtonCircle = ({ className: customClassName }) => (
  <svg
    className={customClassName}
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="70"
    viewBox="0 0 64 70">
    <path
      fill="currentColor"
      d="M1.22814852,15 L-9.27080634e-12,15 C6.32473067,5.93257951 16.832828,0 28.7265536,0 C48.0565198,0 63.7265536,15.6700338 63.7265536,35 C63.7265536,54.3299662 48.0565198,70 28.7265536,70 C16.832828,70 6.32473067,64.0674205 1.4077628e-13,55 L1.22814852,55 C7.41027475,63.4853381 17.424405,69 28.7265536,69 C47.5042351,69 62.7265536,53.7776815 62.7265536,35 C62.7265536,16.2223185 47.5042351,1 28.7265536,1 C17.424405,1 7.41027475,6.51466192 1.22814852,15 Z"
    />
  </svg>
);

ButtonCircle.propTypes = {
  className: PropTypes.string,
};

export default ButtonCircle;
