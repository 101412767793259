import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ({ className, image, sharpImage }) => {
  if (!sharpImage && !image) {
    return null;
  }

  if (sharpImage) {
    return (
      <Img fluid={sharpImage.childImageSharp.fluid} className={className} alt={image.alt || ''} />
    );
  }

  return <img src={image.url} alt={image.alt || ''} className={className} />;
};

Image.defaultProps = {
  image: null,
  sharpImage: null,
};

Image.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  sharpImage: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object,
    }),
  }),
};

export default Image;
