import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ 'aria-hidden': ariaHidden }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 162.8 164.8"
    width="162.8"
    height="164.8"
    aria-hidden={ariaHidden}>
    <title>Commonwealth Fusion Systems Logo</title>
    <path
      fill="currentColor"
      d="M83 0h-3.2l-4.6 21.7 4.6 21.7H83l4.6-21.7zM83 121.3h-3.2l-4.6 21.8 4.6 21.7H83l4.6-21.7zM54.7 4.4l-3 1.1 3.1 22 11.8 18.8 3-1.1-3.1-21.9zM96.2 118.4l-3 1.1 3.1 22 11.8 18.8 3-1-3.1-22zM29.7 18.3l-2.5 2 10.5 19.6 17.5 13.7 2.4-2.1-10.4-19.6zM107.7 111.2l-2.5 2 10.5 19.6 17.5 13.7 2.4-2-10.5-19.6zM10.9 39.8l-1.6 2.8 16.5 14.8 21.1 6.9 1.6-2.8L32 46.7zM115.9 100.5l-1.5 2.7 16.5 14.9 21.1 6.8 1.5-2.7-16.5-14.8zM.5 66.5L0 69.6l20.6 8.3 22.2-.7.5-3.1-20.6-8.3zM120 87.6l-.5 3.1 20.6 8.3 22.2-.8.5-3.1-20.6-8.3zM0 95.1l.5 3.1 22.2.8 20.6-8.3-.5-3.1-22.2-.8zM119.5 74.1l.5 3.1 22.2.7 20.6-8.3-.5-3.1-22.2-.7zM9.3 122.2l1.5 2.7 21.2-6.8 16.5-14.9-1.6-2.7-21.1 6.9zM114.4 61.5l1.5 2.8 21.1-6.9 16.5-14.8-1.5-2.8-21.1 6.9zM27.2 144.5l2.5 2 17.5-13.7 10.4-19.6-2.4-2-17.5 13.7zM105.2 51.5l2.5 2.1 17.4-13.7 10.5-19.6-2.4-2-17.5 13.6zM51.7 159.3l3 1 11.8-18.8 3.1-22-3-1.1-11.8 18.9zM93.2 45.2l3 1.1L108 27.5l3.1-22-3-1.1-11.8 18.9z"
    />
  </svg>
);

Logo.propTypes = {
  'aria-hidden': PropTypes.bool,
};

export default Logo;
