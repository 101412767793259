import React from 'react';
import PropTypes from 'prop-types';
import { className } from '../../../utils';
import styles from './MenuToggleButton.module.scss';

const MenuToggleButton = ({ menuIsOpen, className: customClassName, onClick }) => (
  <button
    {...className(customClassName, styles.button)}
    onClick={onClick}
    aria-label={menuIsOpen ? 'Close menu' : 'Open menu'}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...className(styles.menu__svg, menuIsOpen ? styles.close : styles.open)}>
      <rect className={styles.rect} x="5" y="17" width="26" height="1" />
      <rect className={styles.rect} x="5" y="17" width="26" height="1" />
      <rect className={styles.rect} x="5" y="17" width="26" height="1" />
      <rect className={styles.rect} x="5" y="17" width="26" height="1" />
    </svg>
  </button>
);

MenuToggleButton.propTypes = {
  className: PropTypes.string,
  menuIsOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuToggleButton;
