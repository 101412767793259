import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import { className, easeInQuad, isMobile } from '../../../utils';

import GlobalContext from '../../../context/globals';

import Logo from '../../../svgs/Logo';
import MenuToggleButton from '../MenuToggleButton';
import NavLink from '../NavLink';

// We need to import this fragment manually so that previews work.
import { linkFragmentQuery } from '../../../templates/page';

import styles from './MainNav.module.scss';

const MainNav = ({ prismic, newTheme }) => {
  const navData = prismic.allNavigations.edges.slice(0, 1).pop();

  if (!navData) {
    return null;
  }

  // Data
  const { nav_links } = navData.node;

  // State
  const { mobileMenuVisible, setMobileMenuVisible, theme, setTheme } = useContext(GlobalContext);
  const navRef = useRef(null);
  let navEls = [];
  const focusableEls = [];

  const easeLinks = (i, length, min, max) => min + easeInQuad(i / length) * (max - min);

  const transitionStyles = (i, linkCount, minDur, maxDur) => {
    if (!mobileMenuVisible) {
      return null;
    }

    return {
      transitionDuration: `${easeLinks(i, linkCount, minDur, maxDur)}s`,
    };
  };

  const closeMenuOnResize = () => {
    if (!isMobile()) {
      setMobileMenuVisible(false);
    }
  };

  /**
   * Focuses the nav links after the mobile nav is opened, and disables focus on any links outside
   * the nav.
   */
  const localizeFocusToNav = () => {
    if (focusableEls.length === 0) {
      const main = document.getElementById('site-main');

      if (!main) {
        return;
      }

      main.querySelectorAll('a, button').forEach(el => {
        if (el.getAttribute('tabindex') === null) {
          focusableEls.push(el);
        }
      });
    }

    if (navEls.length === 0 && navRef && navRef.current) {
      navEls = navRef.current.querySelectorAll('a, button');
    }

    focusableEls.forEach(el => el.setAttribute('tabindex', '-1'));
    navEls.forEach(el => el.removeAttribute('tabindex'));
    document.activeElement.blur();
  };

  /**
   * Reverses the above: disables focus on nav links and removes tabindex from any focusable
   * elements in the main content.
   */
  const unlocalizeFocusToNav = () => {
    navEls.forEach(el => el.setAttribute('tabindex', '-1'));
    focusableEls.forEach(el => el.removeAttribute('tabindex'));
  };

  /**
   * Toggle the visibility of the nav.
   */
  const toggleMenuVisibility = () => {
    setMobileMenuVisible(!mobileMenuVisible);

    if (!mobileMenuVisible) {
      localizeFocusToNav();
    } else {
      unlocalizeFocusToNav();
    }
  };

  /**
   * When the nav is mounted, it generally means that we've navigated to a new page.
   */
  useEffect(() => {
    setMobileMenuVisible(false);
    setTheme(newTheme);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', closeMenuOnResize);
    }

    // If we're on a mobile device, remove the nav links from the list of tabbable elements since
    // they're hidden.
    if (isMobile() && navRef && navRef.current) {
      const navLinks = navRef.current.querySelectorAll('a, button');
      if (navLinks) {
        navLinks.forEach(link => link.setAttribute('tabindex', '-1'));
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', closeMenuOnResize);
      }

      // On unmount, restore the ability to focus on elements outside of the nav and remove focus
      // from nav elements.
      unlocalizeFocusToNav();
    };
  }, []);

  return (
    <div
      {...className(styles.nav__wrapper, mobileMenuVisible && styles.menu__visible, styles[theme])}>
      <nav className={styles.nav}>
        <Link to="/" className={styles.logoContainer}>
          <div className={styles.logo}>
            <Logo aria-hidden />

            <span className={styles.logo__name}>
              Commonwealth
              <br />
              Fusion Systems
            </span>
          </div>

          <div className={styles.name}>
            <p className={styles.name__text} tabIndex={'-1'}>
              Commonwealth Fusion Systems
            </p>
          </div>
        </Link>

        <ul {...className(styles.menu)} ref={navRef}>
          {nav_links &&
            nav_links.length &&
            nav_links.map(({ label, link, sub_nav_links }, i) => {
              // If we're trying to link to a careers page, send it to the Lever product.
              const navLink =
                link.__typename === 'PRISMIC_Job'
                  ? {
                      _linkType: 'Link.web',
                      url: 'https://jobs.lever.co/cfsenergy',
                      target: '_blank',
                    }
                  : link;

              return (
                <NavLink
                  key={label}
                  label={label}
                  link={navLink}
                  tag="li"
                  wrapperClass={styles.menu__item}
                  linkClass={styles.menu__itemLink}
                  activeClassName={styles.menu__itemLinkActive}
                  getProps={({ href, location, isPartiallyCurrent }) => {
                    const hrefFirstPath = href.split('/').filter(Boolean)[0];
                    const locationFirstPath = location.pathname.split('/').filter(Boolean)[0];

                    if (
                      (locationFirstPath && hrefFirstPath === locationFirstPath) ||
                      isPartiallyCurrent
                    ) {
                      return {
                        className: `${styles.menu__itemLink} ${styles.menu__itemLinkActive}`,
                      };
                    }
                  }}
                  style={transitionStyles(i, nav_links.length - 1, 0.3, 0.7)}
                  {...(sub_nav_links && sub_nav_links.navigation && sub_nav_links.navigation.length
                    ? {
                        subMenuLinkClass: styles.subMenu__link,
                        subMenuButtonClass: styles.subMenu__button,
                        subLinks: sub_nav_links.navigation.map(nav => ({
                          label: nav.label,
                          link: nav.link,
                        })),
                      }
                    : {})}
                />
              );
            })}
        </ul>

        <MenuToggleButton
          className={styles.menuButton}
          menuIsOpen={mobileMenuVisible}
          onClick={toggleMenuVisibility}
        />
      </nav>
    </div>
  );
};

MainNav.defaultProps = {
  newTheme: '',
};

MainNav.propTypes = {
  prismic: PropTypes.shape({
    allNavigations: PropTypes.object,
  }).isRequired,
  newTheme: PropTypes.string,
};

const mainNavQuery = graphql`
  query MainNavQuery {
    prismic {
      allNavigations {
        edges {
          node {
            nav_links {
              label
              link {
                ...LinkQuery
              }
              sub_nav_links {
                ... on PRISMIC_Site_section {
                  navigation {
                    label
                    link {
                      ...LinkQuery
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MainNavWithQuery = props => (
  <StaticQuery
    query={mainNavQuery}
    render={withPreview(
      data => (
        <MainNav {...data} {...props} />
      ),
      mainNavQuery,
      [linkFragmentQuery],
    )}
  />
);

export default MainNavWithQuery;
