import React from 'react';
import PropTypes from 'prop-types';

import styles from './Wrapper.module.scss';

const Wrapper = ({ children }) => <section className={styles.wrapper}>{children}</section>;

Wrapper.propTypes = {
  children: PropTypes.node,
};

export default Wrapper;
