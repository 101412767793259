import linkResolver from './linkResolver';
import contentSerializer from './contentSerializer';
import { isMobile, isTouch, useMediaQuery } from './mediaQueries';

export { linkResolver, contentSerializer, isMobile, isTouch, useMediaQuery };

/**
 * A collection of utility functions.
 */

/**
 * React helper to aid in adding classes for css modules.
 *
 * @param  {...any} classes The classes to add to an element.
 *
 * @return object
 */
export const className = (...classes) => ({
  className: classes.filter(className => typeof className === 'string').join(' '),
});

/**
 * Uppercase the first letter of a string.
 *
 * @param {string} str The string to uppercase the first letter.
 *
 * @return string
 */
export const ucfirst = str => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Ensures that the given string has a trailing slash.
 *
 * @param {string} str String to add a trailing slash to.
 *
 * @return string
 */
export const trailingSlashIt = str => {
  if (str.substr(-1) === '/') {
    return str;
  }

  return `${str}/`;
};

/**
 * Creates a slug from the given string, which is usually a headline of a section.
 *
 * @param {string} str Text to make into a slug.
 *
 * @return
 */
export const slugify = str =>
  str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

/**
 * Gets the local source of an image, given a regular and sharp image from a GraphQL query.
 *
 * @param {object} image      Default image field.
 * @param {object} sharpImage Transformed image. This object should be the result of a query for
 *                            the `src` field on a fluid query.
 *
 * @return string, or null if no url.
 */
export const getImgUrl = (image, sharpImage) => {
  if (sharpImage) {
    return sharpImage.childImageSharp.fluid.src;
  }

  if (image) {
    return image.url;
  }

  return null;
};

/**
 * Normalize the related link data into an object that can be spread to props.
 *
 * @param {object} linkData Link data from Prismic.
 *
 * @return object that should be spread into props.
 */
export const relatedLinkProps = linkData => {
  if (Array.isArray(linkData)) {
    linkData = linkData.slice(0, 1).pop();
  }

  if (!linkData) {
    return {};
  }

  return {
    ctaUrl: linkResolver(linkData.link),
    ctaText: linkData.label,
  };
};

/**
 * Moderately accelerates a value from zero velocity.
 *
 * Example usage (see src/components/Nav/MainNav/index.js):
 * const easedProperty = (i, length, min, max) => min + easeInQuad(i / length) * (max - min);
 *
 * @param {float} t A number between [0, 1]
 *
 * @return A number between [0, 1]
 */
export const easeInQuad = t => t * t;

/**
 * Appends trailing-slash versions of each path in the passed array. This enables you to just add
 * "/technology" to the array, for example, rather then adding that AND "/technology/".
 *
 * @param {array}  arr Array to check for a match.
 *
 * @return array
 */
export const getPathList = arr => {
  const allPaths = [...arr];

  arr.forEach(v => {
    if (v !== '/') {
      allPaths.push(`${v}/`);
    }
  });

  return allPaths;
};

/**
 * Sorts an array to bring pinned items to the front.
 *
 * @param {array} arr List of content to sort.
 *
 * @return array of items with the pinned ones at the front.
 */
export const sortPinned = arr => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return [];
  }

  return arr.sort((item1, item2) => {
    const pin1 =
      item1.node &&
      item1.node._meta &&
      item1.node._meta.tags &&
      item1.node._meta.tags.includes('Pinned');
    const pin2 =
      item2.node &&
      item2.node._meta &&
      item2.node._meta.tags &&
      item2.node._meta.tags.includes('Pinned');

    if (pin1 && !pin2) {
      return -1;
    }

    if (pin2 && !pin1) {
      return 1;
    }

    return 0;
  });
};
