import { useState, useEffect } from 'react';

// Breakpoint sizes.
const bpMd = 768;

/**
 * Checks whether the window width is smaller than the given breakpoint.
 *
 * @param {int} bp Window size to check against.
 *
 * @return boolean
 */
const isWindowSmallerThan = bp => {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.innerWidth < bp;
};

export const isMobile = () => isWindowSmallerThan(bpMd);

/**
 * Checks whether the current user agent:
 * A: has a primary pointing device with fine selection capabilities
 * B: has a primary pointing device capable of triggering hover states
 *
 * @return boolean
 */

export const isTouch = () => {
  // if we're not in a browser environment, then no need to check anything.
  if (typeof window === 'undefined') {
    return false;
  }

  const isFine = window.matchMedia('(pointer: fine)').matches;
  const canHover = window.matchMedia('(hover: hover)').matches;

  return !(isFine && canHover);
};

const getMatches = query => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches;
  }
  return false;
};

export const useMediaQuery = (breakpoint = bpMd) => {
  const query = `(max-width: ${breakpoint}px)`;

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    function handleChange() {
      setMatches(getMatches(query));
    }

    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener('change', handleChange);
    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, []);

  return matches;
};
