import React from 'react';
import * as PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import { className, contentSerializer } from '../../../utils';

import styles from './Body.module.scss';

const Body = ({ content, dropcap }) => (
  <div className={styles.body}>
    <div {...className(styles.content, dropcap && styles.contentWithDropcap)}>
      <RichText render={content} htmlSerializer={contentSerializer} />
    </div>
  </div>
);

Body.propTypes = {
  content: PropTypes.array,
  dropcap: PropTypes.bool,
};

Body.defaultProps = {
  dropcap: true,
};

export default Body;
