import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { withPreview } from 'gatsby-source-prismic-graphql';

import Button from '../Button';
import { linkResolver } from '../../utils';
import Logo from '../../svgs/Logo';
import NavLink from '../Nav/NavLink';
import Img from '../Image';

// We need to import this fragment manually so that previews work.
import { linkFragmentQuery } from '../../templates/page';

import styles from './Footer.module.scss';

const Footer = ({ cta, prismic }) => {
  const footerData = prismic.allFooters.edges.slice(0, 1).pop();

  if (!footerData) {
    return null;
  }

  const { address, contact_callouts, footer_navigation, cta: defaultCta } = footerData.node;

  let footerCallout = null;

  if (cta) {
    footerCallout = cta;
  } else if (defaultCta) {
    footerCallout = defaultCta;
  }

  return (
    <footer className={styles.footer}>
      {footerCallout && (
        <section className={styles.hiring}>
          <div className={styles.hiring__content}>
            {footerCallout.cta_headline && <h2>{RichText.asText(footerCallout.cta_headline)}</h2>}

            <div className={styles.links}>
              <div className={styles.socials}>
                <p className={styles.socials__text}>{footerCallout.social_media_label}</p>
                <ul className={styles.socials__list}>
                  {footerCallout.social_media.map(({ icon, link, label }) => (
                    <li className={styles.socials__link} key={link.url}>
                      <a
                        href={linkResolver(link)}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={label}>
                        <Img image={icon} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {footerCallout.cta_link && (
                <div>
                  <Button
                    url={linkResolver(footerCallout.cta_link)}
                    text={footerCallout.cta_text}
                    external={footerCallout.cta_link._linkType === 'Link.web'}
                    target={footerCallout.cta_link.target}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      <section className={styles.info}>
        <figure className={styles.info__logo}>
          <Link to="/">
            <Logo />
          </Link>
        </figure>
        {address && (
          <address className={styles.info__address}>
            <RichText render={address} />
          </address>
        )}

        <div className={styles.info__contacts}>
          {contact_callouts.length > 0 &&
            contact_callouts.map(({ contact_email, contact_label }) => (
              <address className={styles.info__contact} key={`${contact_email}_${contact_label}`}>
                {contact_label}
                <br />
                {contact_email && <a href={`mailto:${contact_email}`}>{contact_email}</a>}
              </address>
            ))}

          <div className={styles.info__contact}>
            <a href="https://energy.us7.list-manage.com/subscribe?u=2e7db0453a9549603d236a816&amp;id=2789c5b009">
              Join our mailing list
            </a>
          </div>
        </div>

        <ul className={styles.info__nav}>
          {footer_navigation.length > 0 &&
            footer_navigation.map(({ label, link }) => (
              <NavLink key={label} label={label} link={link} tag="li" />
            ))}
        </ul>
      </section>
    </footer>
  );
};

Footer.defaultProps = {
  cta: null,
};

Footer.propTypes = {
  cta: PropTypes.shape({
    cta_headline: PropTypes.array,
    cta_link: PropTypes.object,
    cta_text: PropTypes.string,
  }),
  prismic: PropTypes.shape({
    allFooters: PropTypes.object,
  }).isRequired,
};

export const footerCtaFragment = graphql`
  fragment FooterCta on PRISMIC__Linkable {
    ... on PRISMIC_Footer_cta {
      cta_headline
      cta_text
      cta_link {
        ...LinkQuery
      }
      social_media_label
      social_media {
        icon
        link {
          ...LinkQuery
        }
        label
      }
    }
  }
`;

export const footerQuery = graphql`
  query FooterQuery {
    prismic {
      allFooters {
        edges {
          node {
            cta {
              ...FooterCta
            }

            address

            contact_callouts {
              contact_email
              contact_label
            }

            footer_navigation {
              label
              link {
                ...LinkQuery
              }
            }
          }
        }
      }
    }
  }
`;

const FooterWithQuery = props => (
  <StaticQuery
    query={footerQuery}
    render={withPreview(
      data => (
        <Footer {...data} {...props} />
      ),
      footerQuery,
      [footerCtaFragment, linkFragmentQuery],
    )}
  />
);

export default FooterWithQuery;
