import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { linkResolver, className, useMediaQuery } from '../../../utils';
import styles from './NavLink.module.scss';

const NavLink = ({
  label,
  link,
  style,
  tag: LinkWrapper,
  wrapperClass,
  linkClass,
  activeClassName,
  getProps,
  onClick,
  subLinks,
  subMenuLinkClass,
  subMenuButtonClass,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const subMenuRef = React.useRef(null);
  const url = linkResolver(link);

  const isTablet = useMediaQuery(899);

  if (!url || !link) {
    return null;
  }

  // If `getProps` is not null, we need to conditionally add that prop to the `Link` component.
  // Simply passing a null value to that prop results in an error in the component.
  const getPropsAttr = getProps ? { getProps } : {};

  const subMenuWrapperProps =
    subLinks && subLinks.length
      ? {
          onMouseEnter: () => {
            setIsExpanded(true);
          },
          onMouseLeave: () => {
            setIsExpanded(false);
          },
        }
      : null;

  // If this is an external link, just use a regular anchor tag.
  const linkMarkup =
    link._linkType === 'Link.web' ? (
      <a
        className={linkClass}
        href={url}
        style={style}
        target={link.target}
        rel={link.target === '_blank' ? 'noopener noreferrer' : ''}
        aria-expanded={subLinks && subLinks.length && !isTablet ? isExpanded : undefined}>
        {label}
      </a>
    ) : (
      <Link
        className={linkClass}
        activeClassName={activeClassName}
        to={url}
        partiallyActive
        {...getPropsAttr}
        onClick={onClick}
        aria-expanded={subLinks && subLinks.length && !isTablet ? isExpanded : undefined}>
        {label}
      </Link>
    );

  const subMenuMarkup = subLinks ? (
    <>
      <button
        type="button"
        aria-expanded={isExpanded}
        onClick={() => {
          setIsExpanded(prev => !prev);
        }}
        onBlur={e => {
          if (subMenuRef.current && !subMenuRef.current.contains(e.relatedTarget)) {
            setIsExpanded(false);
          }
        }}
        {...className(styles.subMenu__button, subMenuButtonClass)}>
        <span className={styles.subMenu__buttonLabel}>Show submenu for {label.toLowerCase()}</span>
        <span>↓</span>
      </button>
      {isExpanded ? (
        <ul
          className={styles.subMenu}
          ref={subMenuRef}
          onBlur={e => {
            if (subMenuRef.current && !subMenuRef.current.contains(e.relatedTarget)) {
              setIsExpanded(false);
            }
          }}>
          {subLinks.map(subLink => (
            <li key={subLink.label} className={styles.subMenu__li}>
              {link._linkType === 'Link.web' ? (
                <a
                  {...className(styles.subMenu__link, subMenuLinkClass)}
                  href={linkResolver(subLink.link)}
                  target={link.target}
                  rel={link.target === '_blank' ? 'noopener noreferrer' : ''}>
                  {subLink.label}
                </a>
              ) : (
                <Link
                  {...className(styles.subMenu__link, subMenuLinkClass)}
                  to={linkResolver(subLink.link)}>
                  {subLink.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  ) : null;

  if (LinkWrapper) {
    return (
      <LinkWrapper className={wrapperClass} style={style} {...subMenuWrapperProps}>
        {linkMarkup}
        {subMenuMarkup}
      </LinkWrapper>
    );
  }

  return (
    <div {...subMenuWrapperProps}>
      {linkMarkup}
      {subMenuMarkup}
    </div>
  );
};

NavLink.defaultProps = {
  tag: null,
  wrapperClass: null,
  linkClass: null,
  activeClassName: null,
  getProps: null,
  onClick: null,
  subLinks: null,
  subMenuLinkClass: null,
  subMenuButtonClass: null,
};

NavLink.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.shape({
    _linkType: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
  tag: PropTypes.string,
  wrapperClass: PropTypes.string,
  linkClass: PropTypes.string,
  activeClassName: PropTypes.string,
  getProps: PropTypes.func,
  onClick: PropTypes.func,
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.shape({
        _linkType: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  subMenuLinkClass: PropTypes.string,
  subMenuButtonClass: PropTypes.string,
};

export default NavLink;
