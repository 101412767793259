import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Elements } from 'prismic-reactjs';
import { Link } from 'gatsby';
import linkResolver from './linkResolver';

/**
 * A custom serializer for Prismic RichText content.
 *
 * @link https://prismic.io/docs/javascript/beyond-the-api/html-serializer
 *
 * @return string if we're making changes, null if the default is good
 */
const contentSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.span:
      return content.split(/(\[\d+\])/).reduce((acc, span, idx) => {
        if (span.match(/\[\d+\]/)) {
          const footnote = (
            <span className="footnote" key={idx}>
              {span.replace(/\[(\d+)\]/, '$1')}
            </span>
          );
          return acc.concat([footnote]);
        }

        return acc.concat(span);
      }, []);

    case Elements.hyperlink:
      return element.data.link_type === 'Web' ? (
        <a href={element.data.url} key={key}>
          {children}
        </a>
      ) : (
        <Link to={linkResolver(element.data)} key={key}>
          {children}
        </Link>
      );

    case Elements.listItem: {
      const reactItem = <div>{children.map(el => el)}</div>;
      const itemString = ReactDOMServer.renderToString(reactItem);
      const newString = itemString.replace(/(\n--\s)(.*)(?=(\n)|<\/div>)/g, '<ul><li>$2</li></ul>');
      return <li key={key} dangerouslySetInnerHTML={{ __html: newString }} />;
    }

    case Elements.preformatted:
      return <div key={key} dangerouslySetInnerHTML={{ __html: children }} />;

    default:
      return null;
  }
};

export default contentSerializer;
