import React from 'react';
import * as PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { className, linkResolver } from '../../../utils';

import NavLink from '../../Nav/NavLink';
import ImageGrid from '../../ImageGrid';
import Img from '../../Image';
import Video from '../../Video';
import Button from '../../Button';

import styles from './Header.module.scss';

const Header = ({
  title,
  photos,
  image,
  sharpImage,
  video,
  subnav,
  overline,
  embedVideo,
  ctaLink,
  ctaLabel,
  secondaryCtaLink,
  secondaryCtaLabel,
}) => {
  const subnavLinks = subnav && subnav.filter(link => link.link);
  const gridPhotos = photos ? photos.slice(0, 1).pop() : [];

  const cta =
    ctaLink && ctaLabel ? (
      <div className={styles.cta}>
        <Button
          url={linkResolver(ctaLink)}
          text={ctaLabel}
          external={ctaLink._linkType === 'Link.web'}
          target={ctaLink.target}
          circleFull
        />
      </div>
    ) : null;

  return (
    <div
      {...className(
        styles.header,
        photos &&
          gridPhotos &&
          (gridPhotos.feature_photo || gridPhotos.feature_video) &&
          styles.headerWithPhotoGrid,
        !image && !sharpImage && !video && !embedVideo && styles.headerWithoutImage,
        subnav && styles.headerWithNav,
      )}>
      <div className={styles.content}>
        <div className={styles.headlines}>
          {overline && <span className={styles.overline}>{overline}</span>}
          <RichText render={title} />
        </div>
        {ctaLink && ctaLabel ? (
          <div className={styles.ctaDesktop}>
            <div className={styles.ctaContent}>{cta}</div>
          </div>
        ) : null}
      </div>

      {((subnavLinks && subnavLinks.length > 0) || image || embedVideo) && (
        <div className={styles.content}>
          {subnavLinks && subnavLinks.length > 0 && (
            <div className={styles.sidebar}>
              <nav>
                <ul className={styles.subNav__items}>
                  {subnavLinks.map(({ label, link }) => (
                    <NavLink
                      key={label}
                      label={label}
                      link={link}
                      tag="li"
                      wrapperClass={styles.subNav__item}
                      linkClass={styles.subNav__link}
                      activeClassName={styles.subNav__linkActive}
                    />
                  ))}
                </ul>
              </nav>

              {secondaryCtaLink && secondaryCtaLabel ? (
                <div className={styles.secondaryCtaContainer}>
                  <div className={styles.secondaryCta}>
                    <Button
                      url={linkResolver(secondaryCtaLink)}
                      text={secondaryCtaLabel}
                      external={secondaryCtaLink._linkType === 'Link.web'}
                      target={secondaryCtaLink.target}
                      circleTan
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
          {image && (
            <div className={styles.image}>
              <Img sharpImage={sharpImage} image={image} />
              {image.copyright && <p className={styles.copyright}>{image.copyright}</p>}
            </div>
          )}
          {video && (
            <div {...className(styles.image, styles.featured)}>
              <div {...className(styles.video)}>
                <Video video={video} />
              </div>
            </div>
          )}
          {embedVideo && embedVideo.html && (
            <div
              className={styles.embedVideo}
              dangerouslySetInnerHTML={{ __html: embedVideo.html }}
            />
          )}
        </div>
      )}

      {photos && gridPhotos && (gridPhotos.feature_photo || gridPhotos.feature_video) ? (
        <div style={{ position: 'relative' }}>
          <ImageGrid photos={gridPhotos} flipOrientation />
        </div>
      ) : null}

      {ctaLink && ctaLabel ? (
        <div className={styles.ctaMobile}>
          <div className={styles.ctaContent}>{cta}</div>
        </div>
      ) : null}
    </div>
  );
};

Header.defaultProps = {
  photos: [],
  image: null,
  sharpImage: null,
  subnav: [],
  overline: null,
};

Header.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.object,
      photoSharp: PropTypes.object,
    }),
  ),
  image: PropTypes.object,
  embedVideo: PropTypes.object,
  overline: PropTypes.string,
  sharpImage: PropTypes.object,
  subnav: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.object,
    }),
  ),
  title: PropTypes.array.isRequired,
  ctaLink: PropTypes.object,
  ctaLabel: PropTypes.string,
  secondaryCtaLink: PropTypes.object,
  secondaryCtaLabel: PropTypes.string,
};

export default Header;
