import { getCookies } from 'gatsby-source-prismic-graphql';
import Prismic from 'prismic-javascript';

/**
 * Adds Prismic javascript to the website when we're in a preview.
 *
 * @return void
 */
const addPrismicScripts = () => {
  // Make sure the document global exists - basically make sure we're running in a browser
  // and not a node environment.
  if (typeof document === 'undefined') {
    return;
  }

  const cookies = getCookies();
  const preview = cookies.has(Prismic.previewCookie);

  if (preview) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.key = 'prismic-script';
    s.src = '//static.cdn.prismic.io/prismic.min.js';
    document.body.append(s);
  }
};

export default addPrismicScripts;
