import { trailingSlashIt } from './index';
import { getCookies } from 'gatsby-source-prismic-graphql';
import Prismic from 'prismic-javascript';

/**
 * Resolve to the appropriate URL given the link object.
 *
 * @param {object} doc A link object data from Prismic.
 *
 * @return string
 */
const linkResolver = doc => {
  if (!doc) {
    return '/';
  }

  if (doc._linkType === 'Link.web' || doc.link_type === 'Web') {
    return doc.url;
  }

  const uid = doc._meta ? doc._meta.uid : doc.uid;
  const type = doc._meta ? doc._meta.type : doc.type;
  const slug_base = doc.section ? doc.section.section_slug : null;

  // Account for previews on unpublished pages.
  const cookies = typeof document === 'undefined' ? new Map() : getCookies();
  const preview = cookies.has(Prismic.previewCookie);

  if (preview && doc.first_publication_date === null) {
    switch (type) {
      case 'page':
        return `/pagePreview?uid=${uid}`;
      case 'press':
        return `/pressPreview?uid=${uid}`;
    }
  }

  switch (type) {
    case 'press':
      return `/news-and-media/${uid}`;
    // case 'job':
    //   return `/careers/${uid}`;
    case 'page':
    case 'devens_updates_page':
    case 'devens_events_page':
    case 'devens_timeline_page':
    case 'devens_public_benefits_page':
      return slug_base ? `/${trailingSlashIt(slug_base)}${uid}` : `/${uid}`;
    case 'tech_page':
      return '/technology';
    case 'press_archive_page':
      return '/news-and-media';
    // case 'careers_archive_page':
    //   return '/careers';
  }

  if (!uid) {
    return '/';
  }

  return `/${uid}`;
};

export default linkResolver;
