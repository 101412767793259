module.exports = [{
      plugin: require('/codebuild/output/src3889970384/src/cfs-website/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"cfs-website","accessToken":"MC5YUy1iYkJFQUFDY0FPUVZm.77-9CO-_ve-_ve-_ve-_vVvvv71F77-9RG_vv71hDncBLu-_ve-_ve-_ve-_ve-_ve-_vWd5PEteIxxk","path":"/preview","previews":true,"sharpKeys":[{}]},
    },{
      plugin: require('/codebuild/output/src3889970384/src/cfs-website/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src3889970384/src/cfs-website/src/layouts/Main.js"},
    },{
      plugin: require('/codebuild/output/src3889970384/src/cfs-website/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-NWFMP94WYW"},
    },{
      plugin: require('/codebuild/output/src3889970384/src/cfs-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
