import React, { useState } from 'react';
import PropTypes from 'prop-types';

const defaultContext = {
  mobileMenuVisible: false,
  theme: '',
};

const GlobalContext = React.createContext(defaultContext);

const GlobalContextProvider = ({ children }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(defaultContext.mobileMenuVisible);
  const [theme, setTheme] = useState(defaultContext.theme);

  return (
    <GlobalContext.Provider
      value={{
        mobileMenuVisible,
        setMobileMenuVisible,
        theme,
        setTheme,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node,
};

export { GlobalContext as default, GlobalContextProvider };
