import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ className: customClassName }) => (
  <svg
    className={customClassName}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="12"
    viewBox="0 0 20 12">
    <g fill="none" stroke="currentColor" strokeLinecap="square" transform="translate(0 -1)">
      <path d="M13 13L19 7M13 1L19 7M.5 7L18.5 7" />
    </g>
  </svg>
);

Arrow.propTypes = {
  className: PropTypes.string,
};

export default Arrow;
