/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import PropTypes from 'prop-types';
import { StaticQuery } from 'gatsby';
import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import linkResolver from './src/utils/linkResolver';
import { timeout } from './src/components/PageTransition';

// Register the link resolver.
registerLinkResolver(linkResolver);

// This is necessary to suppress a propTypes error on StaticQuery.
if (StaticQuery && StaticQuery.propTypes) {
  StaticQuery.propTypes.query = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), timeout);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), timeout);
  }
  return false;
};
